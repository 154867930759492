.toolbar {
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  display: flex;
  justify-content: start;
  gap: 10px;
  width: inherit;
}

.toolbar button {
  padding: 5px 15px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #e7e7e7;
  outline: none;
}

.toolbar button:hover {
  background-color: #d7d7d7;
}
